/* ------------------------------------------------------------ */
/* App.scss */
/* ------------------------------------------------------------ */

html {
    //height : 100%;
}

body {
    //height : 100%;
    margin : 0px;
    font-family : 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color : #ffffff;
    //overflow-y : scroll; /* force scrollbar to show on desktop for all tabs */
}

*:focus {
    outline : none;
}

a {
    text-decoration : none;
}

/* ------------------------------------------------------------ */

$white : #ffffff;
$black : #000000;
$blue : #1652f0;
$gray-dark : #474554;
$gray-light : #aca9bb;
$green-dark : #007e4a;
$green-light : #00b77b;
$player-height : 220px;
$time-bar-height : 26px;

$purple : #9690c5;
$purple-light : #f2ecff;

$blue-grad-1 : #1652f0;
$blue-grad-2 : #556eff;
$blue-grad-3 : #7e8bff;
$blue-grad-4 : #a5aaff;
$blue-grad-5 : #cacaff;

$blue-dark : #0035ca;

/* ------------------------------------------------------------ */

.playerDiv {
    position : fixed;
    top : 0;
    display : flex;
    flex-direction : column;
    justify-content : space-between;
    align-items : center;
    height : $player-height;
    width : 100%;
    background-color : $blue;
    box-shadow : 0px 0px 6px 4px #00000022;
}

.timeBarDiv {
    display : flex;
    justify-content : space-between;
    height : $time-bar-height;
    width : 100%;
    background-color : #ffffff55;
    cursor : pointer;
}

.timeBarSection {
    display : flex;
    flex-grow : 1;
    height : $time-bar-height;
}

.filled {
    background-color : $blue-dark;
}

.selectionDiv {
    display : flex;
    flex-direction : column;
    width : 100%;
    align-items : center;
    margin-top : $player-height + 12px; /* to start below the fixed player div */
    margin-bottom : 60px;
}

.selectionItem {
    display : flex;
    justify-content : center;
    align-items : center;
    background-color : $gray-light;
    color : $white;
    padding : 14px 20px 14px 20px;
    border-radius : 6px;
    margin-top : 10px;
    cursor : pointer;
}

.activeItem {
    background-color : $gray-dark;
}

.headerText {
    color : $white;
    font-size : 18px;
}

.speedRow {
    display : flex;
    flex-direction : row;
    color : $blue-grad-3;
    font-size : 18px;
}

.speedItem {
    padding : 0px 10px 0px 10px;
    cursor : pointer;

    &active {
        @extend .speedItem;
        color : $white;
        cursor : default;
    }
}

.playPauseButton {
    cursor : pointer;
}

/* ------------------------------------------------------------ */
/* -- Desktop/large screens --*/
/* ------------------------------------------------------------ */

@media screen and ( min-width: 481px ) {
    .onlyMobile {
        display : none;
    }

    .selectionDiv {
        flex-direction : row;
        flex-wrap : wrap;
        width : 100%;
        justify-content: center;
    }

    .selectionItem {
        min-width : 260px;
        margin-left : 5px;
        margin-right : 5px;
    }
}

/* ------------------------------------------------------------ */
/* -- mobile/small screens --*/
/* ------------------------------------------------------------ */

@media screen and ( max-width: 480px ) {
    .onlyDesktop {
        display : none;
    }

    .selectionDiv {
        flex-direction : column;
        width : 100%;
        align-items : center;
    }

    .selectionItem {
        width : 70%;
    }
}

/* ------------------------------------------------------------ */
